import { REPORT_STATE_SELECTOR } from "./utils";
import { updateReportFilters } from "./redux/actions";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";

export const config = {
  columns: [
    "gamer_id",
    "partner_id",
    "project_id",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "bonuses",
    "duplicates",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
    "fined_from",
    "miltiaccount_comment",
  ],
  hiddenColumns: [],
  subtotals: [
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "bonuses",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
  ],
  filteredColumns: [
    "gamer_id",
    "partner_id",
    "project_id",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "bonuses",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
  ],
  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  stateSelector: REPORT_STATE_SELECTOR,
  api: API_ROUTES.reports.gamers_ftd.path,
  apiKey: API_ROUTES.reports.gamers_ftd.key,
  fileName: API_ROUTES.reports.gamers_ftd.filename,
  dispatchCallback: updateReportFilters,
};
