import React from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { useGetFroder, usePostFroder } from "../../hooks";
import { Spin } from "antd";
import {FroderCard} from "../../components";

const FroderEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetFroder(id);
  const [editFroder, { isLoading: isPostFroderLoading }] = usePostFroder(id);

  const handleSubmit = (values) => {
    editFroder(values);
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading || isPostFroderLoading}>
          {data && (
            <FroderCard initialValues={data.data} handleSubmit={handleSubmit} />
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FroderEdit;
