import React from "react";
import { Link } from "react-router-dom";

export const PostPreview = ({ post }) => {
  return (
    <div className="news-preview">
      <Link to={`/news-content/${post.url}`} className="news-preview__img">
        <img src={`${post.host}${post.options_parsed.image}`} alt="" />
      </Link>
      <p className="news-preview__date">{post.post_date}</p>
      <div className="news-preview__content">
        <h2>
          <Link to={`/news-content/${post.url}`}>{post.caption}</Link>
        </h2>
        <div dangerouslySetInnerHTML={{ __html: post.short_text }}></div>
      </div>
    </div>
  );
};
