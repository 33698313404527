import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Button, Row, Table, Tag } from "antd";
import { _t } from "../../../../../../../../modules/Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import { getGamerProfilePath } from "./helpers";
import { APP_ROUTES } from "../../../../../../../../system/constants/constantsUrl";
import { getUserProfilePath } from "../../../../../Managers/pages/ManageableList/components/ManageableUsersTable/helpers";

const FrodersListTable = ({ data }) => {
  const history = useHistory();
  const generateEdit = (id) => {
    return generatePath(APP_ROUTES.manage.fraudsters.edit, {
      id,
    });
  };

  const columns = [
    {
      title: _t("gamer"),
      dataIndex: "gamer_id",
      render: (text, record) => (
        <>
          <Row>
            <Link to={getGamerProfilePath(record.gamer_id, record.project_id)}>
              {text}
            </Link>
          </Row>
          {record.partner && (
            <Row>
              <span color="volcano">{_t("partner")}:</span>
              <Link to={getUserProfilePath(record.partner_id)}>
                {record.partner.login}
              </Link>
            </Row>
          )}
        </>
      ),
    },
    {
      title: _t("comment"),
      dataIndex: "comment",
    },
    {
      title: _t("fined_at"),
      dataIndex: "fined_at",
      render: (text, record) => (
        <>
          <Row>
            <Tag color="blue">{_t("fined_at")}</Tag> {text}
          </Row>
          <Row>
            <Tag color="green">{_t("fine_from")}</Tag> {record.added_at}
          </Row>
        </>
      ),
    },
    {
      title: _t("manage"),
      dataIndex: "id",
      render: (text, record) => (
        <Button
          shape="round"
          onClick={() => {
            history?.push(generateEdit(record.id));
          }}
        >
          {_t("edit")}
        </Button>
      ),
    },
  ];
  return (
    <Table dataSource={data} columns={columns} pagination={false} rowKey="id" />
  );
};

export default FrodersListTable;
