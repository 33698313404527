import React from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { usePostFroderBulk } from "../../hooks";
import { Spin } from "antd";
import { FroderBulkCard } from "../../components";

const FroderBulk = () => {
  const [createFroder, { isLoading: isPostLoading }] = usePostFroderBulk();

  const handleSubmit = (values) => {
    createFroder(values);
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isPostLoading}>
          <FroderBulkCard handleSubmit={handleSubmit} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FroderBulk;
