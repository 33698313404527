import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Form, Input, Row, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import {FroderContentCardType} from "./types";

const FroderCard: React.FC<FroderContentCardType> = ({
  initialValues,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>
          {_t("fraudster_edit_card_title")}
        </Typography.Title>
      </div>
      <Form onFinish={handleSubmit} layout="vertical" form={form}>
        <Form.Item name="id" initialValue={initialValues.id} />
        <Typography.Title level={5} style={{ marginBottom: "15px" }}>
          {_t("gamer_id")}: {initialValues.gamer_id}
        </Typography.Title>
        <Form.Item
          name="comment"
          label={_t("fraudster_placeholder_comment")}
          initialValue={initialValues.comment}
          rules={[
            {
              required: true,
              message: _t("comment_field_required"),
            },
          ]}
        >
          <Input placeholder={_t("fraudster_placeholder_comment")} />
        </Form.Item>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default FroderCard;
