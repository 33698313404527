import React from "react";
import how_work_1 from "../../../assets/images/outer/how_work_1.svg";
import how_work_2 from "../../../assets/images/outer/how_work_2.svg";
import how_work_3 from "../../../assets/images/outer/how_work_3.svg";
import how_work_4 from "../../../assets/images/outer/how_work_4.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import CustomButton from "../../Common/components/CustomButton";
import { Typography } from "antd";
import HowItWorkCard from "./HowItWorkCard";
import { Link } from "react-router-dom";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    icon: how_work_1,
  },
  {
    title: _t("how_2"),
    icon: how_work_2,
  },
  {
    title: _t("how_3"),
    icon: how_work_3,
  },
  {
    title: _t("how_4"),
    icon: how_work_4,
  },
];

export type howItWorksProps = {
  getStarted: () => void;
};

const HowItWorks: React.FC<howItWorksProps> = ({ getStarted }) => {
  const howItWorksCards = slides(_t);
  const howItWorkItem = (card, idx) => (
    <li className="how-it-work-list__item" key={card.title + idx}>
      <HowItWorkCard info={card} />
    </li>
  );

  return (
    <section className="custom-section">
      <div className="container">
        <h2 className="custom-section__title">{_t("how_it_works")}</h2>
        <ul className="how-it-work-list">
          {howItWorksCards.map(howItWorkItem)}
        </ul>
      </div>
    </section>
  );
};

export default HowItWorks;
