import { useDispatch } from "react-redux";
import { signinAction } from "./actions";
import { useMutation } from "react-query";
import { getUserBasic } from "../User/actions";
import { notify } from "../../system/utils/notification";

export const useSignin = (_t) => {
  const successMessage = _t("success_login");
  const errorMessage = _t("check_credentials");
  const dispatch = useDispatch();
  return useMutation((data: any) => dispatch(signinAction(data)), {
    onSuccess: (data: any) => {
      dispatch(getUserBasic({ user_id: data.payload.user_id }));
      notify("success", successMessage);
    },
    onError: (error) => {
      notify("success", errorMessage);
    },
  });
};
