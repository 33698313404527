import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useNewsList } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Spin } from "antd";
import { PostPreview } from "../components/PostPreview";
import { NavLink } from "react-router-dom";

const NewsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useNewsList({
    language: locale,
  });
  return (
    <LandingTemplate>
      <section className="custom-section">
        <div className="container">
          <h2 className="custom-section__title">{_t("news")}</h2>
          <br />
          <Spin spinning={isLoading}>
            <div className="news-grid">
              {data?.news?.map((post) => (
                <PostPreview post={post} key={post.id} />
              ))}
            </div>
          </Spin>
        </div>
      </section>
    </LandingTemplate>
  );
};

export default NewsList;
