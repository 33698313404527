import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import PostBackOptionsPage from "../components/PostBackOptionsPage";

const PostbackOptions = () => {
  return (
    <LandingTemplate>
      <PostBackOptionsPage />
    </LandingTemplate>
  );
};

export default PostbackOptions;
