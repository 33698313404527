import React from "react";
import { Faq } from "../../api";

type QAProps = {
  faq: Faq;
};

export function QA({ faq }: QAProps) {
  return (
    <div className="faq-item">
      <p
        className="faq-item__question"
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.question.replace(/<[^>]+>/g, ""),
        }}
      ></p>
      <p
        className="faq-item__answer"
        dangerouslySetInnerHTML={{
          __html: faq.translations[0]?.answer.replace(/<[^>]+>/g, ""),
        }}
      ></p>
    </div>
  );
}
