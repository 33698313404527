import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getItem,
  getUserId,
} from "../../../../system/helpers/localstorageHelper";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import { AdminMails, UserMails } from "./index";
import classNames from "classnames";
import { useComponentVisible } from "../../../../common/hooks";

interface UserProfilePreviewProps {
  logout: () => void;
  collapsed: boolean;
}
const UserProfilePreview: React.FC<UserProfilePreviewProps> = ({
  logout,
  collapsed,
}) => {
  const userInfo = useSelector((state) => state?.user?.basicInfo);
  const isAdmin = getItem("role") === "admin";
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const handleVisibleChange = () => setIsComponentVisible(!isComponentVisible);

  useEffect(() => {
    if (!isComponentVisible) setIsComponentVisible(false);
  }, [collapsed, isComponentVisible]);

  const adminContent = (
    <div
      className={classNames("user-profile-preview__content", {
        "user-profile-preview__content--collapsed": collapsed,
      })}
    >
      {isAdmin ? <AdminMails /> : <UserMails />}
      <div className="user-profile-preview__info">
        <Link
          to={APP_ROUTES.user.main}
          className="user-profile-preview__info-text"
        >
          {userInfo.login ? (
              <>
                {userInfo.login}
                {"  "}
                <span className="user-profile-preview__info-id">
                ID: {userInfo.id}
              </span>
              </>
          ) : (
              "Loading..."
          )}
        </Link>
        <button title="logout" className="logout-btn" onClick={logout}>
          Logout
          <LogoutOutlined />
        </button>
      </div>
    </div>
  );

  return (
    <div className="user-profile-preview" ref={ref}>
      {collapsed && (
        <>
          <button
            className="user-profile-preview__icon"
            onClick={handleVisibleChange}
          />
          <div
            className={classNames("user-profile-preview__drop", {
              "user-profile-preview__drop--visible": isComponentVisible,
            })}
          >
            {adminContent}
          </div>
        </>
      )}

      {!collapsed && adminContent}
    </div>
  );
};

export default UserProfilePreview;
