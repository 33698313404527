import React from "react";
import { Form, Input, Checkbox, Spin } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import Button from "../../Common/components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";
import { getItem, getLocale } from "../../../system/helpers/localstorageHelper";
import { INVITATION_CODE } from "../../Landing/constants";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

export function RegistrationForm({ onSubmit, isLoading }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const locale = getLocale();
  const recaptchaRef = React.createRef();
  const invitationCode = getItem(INVITATION_CODE);
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Form
      form={form}
      name="register"
      onFinish={onSubmit}
      initialValues={{
        invitation_code: invitationCode,
      }}
      scrollToFirstError
      className="auth-form"
      {...formLayout}
    >
      {invitationCode && (
        <Form.Item name="invitation_code" hidden={true}>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="login"
        label={_t("Login")}
        rules={[
          {
            required: true,
            message: _t("enter_login_message"),
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label={_t("E-mail")}
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label={_t("Password")}
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
          },
          {
            min: 6,
            message: _t("min_password_message"),
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        label={_t("confirm_password")}
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: _t("confirm_password_message"),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(_t("error_confirm_password_message"));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="phone"
        label={_t("Contacts")}
        rules={[{ required: true, message: _t("Please input your contacts!") }]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(_t("accept_terms")),
          },
        ]}
        wrapperCol={{ span: 24 }}
        className="auth-check"
      >
        <Checkbox>
          {_t("agree_with")}{" "}
          <a href={APP_ROUTES.terms}> {_t("terms_conditions")}</a>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="g-recaptcha-response"
        rules={[
          {
            required: true,
            message: _t("recaptcha_required"),
          },
        ]}
        className="recaptcha"
        wrapperCol={{ span: 24 }}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.google_recaptcha_sitekey}
          hl={locale}
        />
      </Form.Item>
      <div className="auth-form__footer">
        <button type="submit" className="btn" disabled={isLoading}>
          {_t("submit")}
        </button>
        <button
          type="button"
          className="btn btn--second"
          onClick={() => history.goBack()}
        >
          {_t("Back")}
        </button>
        <NavLink to={APP_ROUTES.login.main}>
          {_t("have_account")} {_t("sign_in")}!
        </NavLink>
      </div>
    </Form>
  );
}
