import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { useManagersCreate, useManagersList } from "../apiHooks";
import { ManagerForm } from "../components/ManagerForm/ManagerForm";
import { ManagerList } from "../components/ManagerList/ManagerList";

const Managers = () => {
  const [createManager, { isLoading: isManagerCreating }] = useManagersCreate();
  const { data, isLoading } = useManagersList({});

  return (
    <MainLayout>
      <PageTemplate title={_t("Trackers")}>
        <ManagerForm
          onSubmit={createManager}
          initialValues={{ name: "" }}
          isSubmitting={isManagerCreating}
        />
        <ManagerList loading={isLoading} data={data?.data?.data}></ManagerList>
      </PageTemplate>
    </MainLayout>
  );
};

export default Managers;
