import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import TermsPage from "../components/TermsPage";

const Terms = () => {
  return (
    <LandingTemplate>
      <TermsPage />
    </LandingTemplate>
  );
};

export default Terms;
