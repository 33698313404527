import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useTournaments } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import { TournamentPreview } from "../components/TournamentPreview";

const TournamentsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useTournaments({
    language: locale,
  });
  return (
    <LandingTemplate>
      <section className="tournaments-list">
        <h2>{_t("tournaments")}</h2>
        <br />
        <Spin spinning={isLoading}>
          {data?.tournaments?.map((tournament) => (
            <NavLink to={`/tournament/${tournament.id}`} key={tournament.id}>
              <TournamentPreview tournament={tournament} />
            </NavLink>
          ))}
        </Spin>
      </section>
    </LandingTemplate>
  );
};

export default TournamentsList;
