import React, { useCallback } from "react";
import { Col, Form, Row, Space } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import {
  SimpleInput,
  Button,
} from "../../../../../../modules/Common/components";

export const FiltersWidget = ({ onSubmit, initialValues, isSubmitting }) => {
  const [form] = Form.useForm();
  const onFinish = useCallback(
    async (values) => {
      const res = await onSubmit(values);
      form.resetFields();
      return res;
    },
    [form, onSubmit]
  );

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
      style={{ padding: 10 }}
    >
      <Row align="middle" gutter={5}>
        <Col flex="auto">
          <Space>
            <Form.Item name="gamer" noStyle>
              <SimpleInput placeholder={_t("gamer")} />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                loading={isSubmitting}
                type="primary"
                title={_t("search")}
                htmlType="submit"
              ></Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
export default FiltersWidget;
