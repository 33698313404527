import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import aboutSectionImg from "../../../assets/images/outer/about-img.png";
import { Typography } from "antd";

const listItems = (_t: any) => [
  _t("about_item1"),
  _t("about_item2"),
  _t("about_item3"),
  _t("about_item4"),
  _t("about_item5"),
];

const AboutSection: React.FC = () => {
  const listContent = listItems(_t).map((item, idx) => (
    <li key={idx}>{item}</li>
  ));
  return (
    <section className="custom-section">
      <div className="container">
        <div className="about-us">
          <h2>{_t("about_us_title")}</h2>
          <ul className="about-list">{listContent}</ul>
          <a
            href={_t("project_outer_link")}
            target="_blank"
            rel="noreferrer"
            className="btn  btn--big"
          >
            {_t("read_more")}
          </a>

          <div className="about-us__img">
            <img src={aboutSectionImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
