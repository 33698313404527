import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner_1 from "../../../assets/images/banner_1.jpg";
import banner_2 from "../../../assets/images/banner_2.jpg";
import banner_3 from "../../../assets/images/banner_3.jpg";
import banner_4 from "../../../assets/images/banner_4.jpg";
import BannerSlide from "./BannerSlide";
import { _t } from "../../Common/components/InjectIntlContext";
import { onGetStarted } from "../constants";

export type mainBannerSliderProps = {};
const bannerSlides = (_t: any) => [
  {
    backgroundImg: banner_1,
    title: _t("slide_1_title"),
    subtitle: _t("slide_1_text"),
    btnLabel: _t("get_started"),
    onClick: () => onGetStarted(),
  },
];

const slickSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  fade: true,
};

const MainBannerSlider: React.FC<mainBannerSliderProps> = () => {
  const slide = (slide, idx) => <BannerSlide info={slide} key={idx + "bs"} />;
  return (
    <div className="main-banner">
      <Slider {...slickSettings}>{bannerSlides(_t).map(slide)}</Slider>
    </div>
  );
};

export default MainBannerSlider;
