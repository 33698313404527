import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Input, Typography } from "antd";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { FroderBulkContentCardType } from "./types";
import BrandSelect from "../BrandSelect/BrandSelect";

const FroderBulkCard: React.FC<FroderBulkContentCardType> = ({
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <Card className="inner-form-card">
      <div className="inner-form-card__top">
        <Typography.Title level={4}>{_t("fraudster_card_title")}</Typography.Title>
      </div>
      <Form onFinish={handleSubmit} layout="vertical" form={form}>
        <Form.Item name="id" />
        <BrandSelect />
        <Form.Item
          name="gamers"
          label={_t("fraudster_placeholder_gamers")}
          rules={[
            {
              required: true,
              message: _t("gamers_field_required"),
            },
          ]}
        >
          <Input.TextArea
            rows={8}
            placeholder={_t("fraudster_placeholder_gamers")}
          />
        </Form.Item>
        <Form.Item
          name="comment"
          label={_t("fraudster_placeholder_comment")}
          rules={[
            {
              required: true,
              message: _t("comment_field_required"),
            },
          ]}
        >
          <Input placeholder={_t("fraudster_placeholder_comment")} />
        </Form.Item>

        <div className="inner-form-card__controls">
          <Button size="large" shape="round" onClick={() => history.goBack()}>
            {_t("Back")}
          </Button>
          <Button size="large" shape="round" type="primary" htmlType="submit">
            {_t("submit")}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default FroderBulkCard;
