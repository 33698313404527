import React from "react";
import { Form, Select } from "antd";
import {BrandSelectSelectType} from "./types";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import {useGetBrandsList} from "../../hooks";
import {createOptions} from "../../../../../../common/helpers";

const BrandSelect: React.FC<BrandSelectSelectType> = () => {
  const { data, isLoading } = useGetBrandsList();
  return (
    <Form.Item
      name="project_id"
      label={_t("project")}
      rules={[
        {
          required: true,
          message: _t("project_id_field_required"),
        },
      ]}
    >
      <Select options={createOptions(data?.projects, "name", "id")} loading={isLoading} />
    </Form.Item>
  );
};

export default BrandSelect;
