import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useTestimonials } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import { Card, Divider, Spin, Typography } from "antd";
import { Testimonial } from "../components/Testimonial";
import classnames from "classnames";

const Testimonials = () => {
  const { data, isLoading } = useTestimonials({ language: getLocale() });

  return (
    <LandingTemplate>
      <section className="custom-section">
        <div className="container">
          <Typography.Title level={2} className="custom-section__title">
            {_t("testimonials")}
          </Typography.Title>
          <Spin spinning={isLoading}>
            <div className={classnames("testimonials")}>
              {data?.testimonials.map((testimonial) => (
                <Testimonial key={testimonial.id} testimonial={testimonial} />
              ))}
            </div>
          </Spin>
        </div>
      </section>
    </LandingTemplate>
  );
};

export default Testimonials;
